import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './projects.scss';

interface ProjectsProps {
    data: {
        projectsYaml: {
            projects: Project[];
        };
    };
}
//TODO: fix projects carousel (images are different sizes)
const Projects: React.FC<ProjectsProps> = ({ data }: ProjectsProps) => {
    const projects = data.projectsYaml.projects;
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Projects"
                url="projects/"
                description="AI Summer's projects and repositories"
            />
            <PageBanner className="page-banner-single-item">
                <Fragment>
                    <h1>Projects</h1>
                    <h3>Explore our projects, libraries and repositories</h3>
                </Fragment>
                <Fragment>
                    {/* <div className="projects__carousel-wrapper">
                        <Carousel slidesNum={2}>
                            <Img
                                fluid={projects[0].image.childImageSharp.fluid}
                            ></Img>
                            <Img
                                fluid={projects[1].image.childImageSharp.fluid}
                            ></Img>
                        </Carousel>
                    </div> */}
                </Fragment>
            </PageBanner>

            <PageSection className="projects">
                <Container>
                    <Group size={2} className="projects__block">
                        <Block
                            direction={Direction.vertical}
                            className="projects__block__details"
                        >
                            <h3>{projects[0].title} </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: projects[0].description || ''
                                }}
                            ></div>
                            <Link to={projects[0].url}>Learn more</Link>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="projects__block__image"
                        >
                            <Img
                                fluid={projects[0].image.childImageSharp.fluid}
                                alt={projects[0].title}
                            ></Img>
                        </Block>
                    </Group>
                    <Group size={2} className="projects__block">
                        <Block
                            direction={Direction.vertical}
                            className="projects__block__image"
                        >
                            <Img
                                fluid={projects[1].image.childImageSharp.fluid}
                                alt={projects[1].title}
                            ></Img>
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="projects__block__details"
                        >
                            <h3>{projects[1].title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: projects[1].description || ''
                                }}
                            ></div>
                            <Link to={projects[1].url}>Learn more</Link>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const projectsQuery = graphql`
    {
        projectsYaml {
            projects {
                title
                description
                url
                image {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
        }
    }
`;

export default Projects;
